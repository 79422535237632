/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Dynamically get window.innerWidth and window.innerHeight values.
 */

import { ref, onBeforeMount, onUnmounted } from "vue";
import type { Ref } from "vue";

export const handleResize = (): {
    windowWidth: Ref<number>;
    windowHeight: Ref<number>;
    windowYOffset: Ref<number>;
} => {
    const windowWidth: Ref<number> = ref(window.innerWidth);
    const windowHeight: Ref<number> = ref(window.innerHeight);
    const windowYOffset: Ref<number> = ref(window.pageYOffset);
    const setNewSizes = (): void => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
        windowYOffset.value = window.pageYOffset;
    };
    onBeforeMount(() => {
        window.addEventListener("resize", setNewSizes);
    });
    onUnmounted(() => {
        window.removeEventListener("resize", setNewSizes);
    });

    return {
        windowHeight,
        windowWidth,
        windowYOffset,
    };
};
