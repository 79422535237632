const apiUrlGenerator = (): string => {
    const url = window.location.origin + "/source/api/view/";
    if (process.env.NODE_ENV === "production") return url;
    const samples = {
        // apiUrl: 'https://g2.palindroma.ge/source/api/view/',
        apiUrl: "https://g2.ge/source/api/view/",
    };
    return samples.apiUrl;
};

export default {
    apiUrl: apiUrlGenerator(),
    websiteUrl: window.location.origin,
};
