import { defineStore } from "pinia";


import get from "lodash-es/get";
import find from "lodash-es/find";
import size from "lodash-es/size";

import fn from "../../../helpers/func";
import { menu_mutation } from "./menu_helpers";
import {
    LocalizedMenuItem,
    MenuItem,
    StateInterface,
} from "../../ts/interfaces/globalState.interface";
import {
    ContentData,
    ContentDataListItem,
} from "@/ts/interfaces/contentData.interface";

export const useGlobalStore = defineStore({
    id: "globalStore",
    state: (): StateInterface => ({
        indx: null, /// all the main data loaded by default
        conf: {}, /// project global configuration
        cache: {}, /// all the data tahat comes while website runing
        tmp: {}, /// temporary data for short use

        current_content: {
            secondary: {},
        }, /// to store all the content types data
        current_content_buffer: {}, /// to store current content before use new values
        current_content_request_status: "", /// status for current content loaded/// 'pending' while request; 'done' after loaded content from server; 'error' if there is server error
        current_content_last_request: "", /// latest current content request stringify. /// to controll request dublications
        //@ts-ignore
        selected_menu: {
            configs: [],
            id: null,
            inserted_at: "",
            media: {},
            menu_type: "",
            pid: null,
            primary_data: [],
            primary_template: "",
            redirect_url: "",
            secondary_data: [],
            secondary_template: "",
            seo: {
                title: "",
                description: "",
                keywords: "",
            },
            set_home: null,
            sort: null,
            titles: {},
            updated_at: "",
            url_slug: "",
            currentLayoutTemplate: "",
        },
        selected_menu_locales: {},
        locale: "",
        headerScrolled: false,
        disableScrollStatus: true,
        isLoaded: false,
        // currency: "₾",
        currency: "$",
        isRightColumn: false,
        // scrollToTopLoco: false,
        translations: {},
        siteMode: "production",
        triggerUpdate: false,
        isBurgerOpen: false,
        Loco: null,
        locoScrollY: 0,
        projectsMenu: [],
        projectItems: [],
        projectItemsFiltered: [],
        topMenu: [],
        footerMenu: [],
    }),
    //
    getters: {
        getSingleUrl:
            (state: StateInterface) =>
                (params: ContentDataListItem, currentPage: number): string => {
                    if (!get(params, "id")) return "#";
                    let singlePath = "/singleview/" + params.id + "-" + params.slug;

                    if (currentPage === 1) {
                        return (
                            state.selected_menu.full_url_nohash + "" + singlePath
                        );
                    }

                    let menuPage: MenuItem;

                    if (params.singlePageRoute) {
                        menuPage = find(state.indx.menus, {
                            id: params.singlePageRoute,
                        });
                    } else if (
                        parseInt(
                            get(state.indx, [
                                "contentTypes",
                                params.content_type,
                                "settings",
                                "defaultSingleRoute",
                            ])
                        )
                    ) {
                        menuPage = find(state.indx.menus, {
                            id: parseInt(
                                state.indx.contentTypes[params.content_type]
                                    .settings.defaultSingleRoute
                            ),
                        });
                    }

                    let ret: string = "";

                    if (get(menuPage, "full_url_nohash")) {
                        ret = menuPage.full_url_nohash + "" + singlePath;
                    } else {
                        ret = state.selected_menu.full_url_nohash + "" + singlePath;
                    }

                    return ret;
                },
    },
    actions: {
        setTranslatedMenus() {
            const localizedSortedMenus = menu_mutation(
                this.indx.menus,
                this.locale
            );
            this.indx.menus = localizedSortedMenus;
            this.topMenu = this.indx.menus.filter(
                (m: LocalizedMenuItem) =>
                    m.menu_type === "top" && m.url_slug !== "projects"
            );
            this.projectsMenu = this.indx.menus.filter(
                (m: LocalizedMenuItem) =>
                    m.url_slug === "projects" && m.menu_type === "top"
            );
        },
        scrollTopTopLoco(duration: number, disableLerp: boolean) {
            if(this.Loco === null) return;
            this.Loco.scrollTo("top", {
                duration,
                disableLerp,
            });
        },
        async fetchIndx() {
            const tmp: {
                status: number;
                data: StateInterface;
            } = await fn.postData("main/indx");
            if (tmp.status != 200) return false;
            this.indx = tmp.data;

            if (!get(this.indx.locales, this.locale)) {
                this.locale = get(tmp.data, "locale");
            }
            this.setTranslatedMenus();

            // return false;
        },
        async indxTranslatable() {
            let tmp = await fn.postData("main/indxTranslatable");

            if (tmp.status != 200) return false;
            for (let [key, val] of Object.entries(tmp.data)) {
                this.indx[key] = val;
            }
            this.setTranslatedMenus();
        },
        async getBulkData(params: { [key: string]: any }) {
            if (!get(params, "key")) return false;

            if (size(get(this.cache, "params.key")) && !get(params, "force"))
                return false;

            let tmp = await fn.postData("main/getBulkData", params);

            if (tmp.status != 200) return false;

            this.cache[params.key] = tmp.data;
        },
        async getTranslations() {
            if (Object.keys(this.translations).length) return;
            let tmp = await fn.postData("main/getTranslations");
            if (tmp.status != 200) return false;
            this.translations = tmp.data;
        },

        async getCurrentContentProto(params: LocalizedMenuItem) {
            if (!params || !params.id) {
                return false;
            }

            const isDuplicate = this.indx.menus.filter(
                (m: LocalizedMenuItem) =>
                    params?.url === m.url && m.menu_type === "main_menu"
            );

            if (isDuplicate.length) {
                params = isDuplicate[0];
            }
            let request: {
                contentid: number;
                selectedlan: string;
                [key: string]: any;
            } = {
                contentid: params.id,
                selectedlan: this.locale,
            };
            if (params.routeParams) {
                for (let i in params.routeParams) {
                    request[i] = params.routeParams[i];
                }
            }

            let current_content_last_request = JSON.stringify(request);

            if (
                current_content_last_request ==
                this.current_content_last_request
            )
                return false;
            this.current_content_last_request = current_content_last_request;
            this.current_content_request_status = "pending";

            let res = await fn.postData("main/getCurrentContent", request);

            if (res.status != 200) {
                this.current_content = {};
                this.current_content_request_status = "error";

                return false;
            }

            const secondaryData: ContentData = res?.data?.secondary;

            for (const comp of Object.values(secondaryData)) {
                if (!comp?.conf?.hashTag?.length) {
                    comp.conf.hashTagSlug = "nohashtag";
                } else {
                    const splitHashTag = comp.conf.hashTag.split(" ");
                    const splitHashTagJoined = splitHashTag.join("");
                    comp.conf.hashTagSlug = splitHashTagJoined.toLowerCase();
                }
            }

            this.current_content_buffer = res.data;
            this.current_content_request_status = "done";
        },
    },
});
