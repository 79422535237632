/* eslint-disable */
//

import "./assets/styles/main.scss";
import type { App as AppInterface } from "vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import get from "lodash-es/get";
import size from "lodash-es/size";
import includes from "lodash-es/includes";
import router from "./router";
import fn from "./helpers/func";

//@ts-ignore
import vClickOutside from "click-outside-vue3";
import { createHead } from "@vueuse/head";

const pinia = createPinia();
const head = createHead();
declare module "vue" {
    interface ComponentCustomProperties {
        $get: typeof get;
        $size: typeof size;
        $includes: typeof includes;
        $fn: typeof fn;
    }
}
const lodashMethods = {
    install(app: AppInterface) {
        app.config.globalProperties.$get = get;
        app.config.globalProperties.$size = size;
        app.config.globalProperties.$fn = fn;
    },
};

//@ts-ignore
const app = createApp(App);
app.use(pinia);
app.use(lodashMethods);
app.use(router);
app.use(head);
app.use(vClickOutside);

// ALLIANCE GOOGLE MAP API KEY - AIzaSyBFcWIvIr-RXyy90fnAZfxwgRAQ1Oo4EgE

app.mount("#app");
