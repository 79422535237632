<template>
    <svg
        width="89"
        height="89"
        viewBox="0 0 89 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            opacity="0.5"
            cx="44.5"
            cy="44.5"
            r="43.5"
            stroke="#FFEFE1"
            stroke-width="2"
        />
        <path
            d="M56.2539 42.7679C57.5872 43.5377 57.5872 45.4622 56.2539 46.232L40.1246 55.5442C38.7913 56.314 37.1246 55.3518 37.1246 53.8122L37.1246 35.1877C37.1246 33.6481 38.7913 32.6858 40.1246 33.4556L56.2539 42.7679Z"
            fill="#FFFFFF"
        />
    </svg>
</template>
