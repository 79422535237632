/**
 * @author Irakli Kandelaki
 */

import { handleResize } from "./resizeHandler";

/**
 * @summary Split text into different array elements by "/" symbol.
 */

const { windowHeight } = handleResize();

export default (): void => {
    const parent = document.querySelector<HTMLElement>("#app");

    parent.addEventListener('scroll', () => {
        const els = document.querySelectorAll<HTMLElement>(`[data-scroll-speed]`);
        if (!els.length) return;
        const y = parent.scrollTop;
        els.forEach((el) => {
            const prlxVal: number = Number(el.dataset.scrollSpeed);
            if (isNaN(prlxVal)) return;
            const elTop: number = el.getBoundingClientRect().top;
            const isElVisible: boolean = elTop - windowHeight.value <= 50 ? true
                : false;
            const elDistFromTop: number = elTop + y;
            const yVal: number = (y + (windowHeight.value + 200)) - elDistFromTop;

            if (!isElVisible) return;

            if (!el.dataset.scrollDirection) {
                el.style.transform = `translateY(${prlxVal * (yVal / 20)}px)`
            } else {
                el.style.transform = `translateX(${prlxVal * (yVal / 20)}px)`

            }



        })
    })





}