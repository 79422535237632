<template>
    <svg width="89" height="90" viewBox="0 0 89 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.39337" width="87" height="87" rx="43.5" fill="#333333" stroke="#FFEFE1" stroke-width="2" />
        <path
            d="M58.1586 55.0413L47.9539 44.8367L58.4987 34.2919C58.9648 33.8258 59.2326 33.1996 59.2433 32.551C59.2539 31.9025 59.0065 31.2847 58.5554 30.8337C58.1043 30.3826 57.4866 30.1352 56.838 30.1458C56.1895 30.1564 55.5633 30.4243 55.0972 30.8904L44.5524 41.4351L34.3477 31.2305C33.8967 30.7794 33.2789 30.532 32.6304 30.5426C31.9818 30.5533 31.3556 30.8211 30.8895 31.2872C30.4234 31.7533 30.1556 32.3795 30.1449 33.0281C30.1343 33.6766 30.3817 34.2944 30.8328 34.7454L41.0374 44.9501L30.4927 55.4949C30.0266 55.961 29.7587 56.5872 29.7481 57.2357C29.7375 57.8843 29.9849 58.502 30.436 58.9531C30.887 59.4042 31.5048 59.6516 32.1533 59.641C32.8019 59.6303 33.4281 59.3625 33.8942 58.8964L44.439 48.3516L54.6436 58.5562C55.0947 59.0073 55.7125 59.2548 56.361 59.2441C57.0095 59.2335 57.6357 58.9657 58.1019 58.4996C58.568 58.0334 58.8358 57.4072 58.8464 56.7587C58.8571 56.1102 58.6096 55.4924 58.1586 55.0413Z"
            fill="#FFEFE1" />
        <path
            d="M58.1585 55.0408L47.9539 44.8362L58.4987 34.2914C58.9648 33.8253 59.2326 33.1991 59.2433 32.5505C59.2539 31.902 59.0065 31.2842 58.5554 30.8332C58.1043 30.3821 57.4866 30.1347 56.838 30.1453C56.1895 30.1559 55.5633 30.4237 55.0972 30.8899L44.5524 41.4346L34.3477 31.23C33.8967 30.7789 33.2789 30.5315 32.6304 30.5421C31.9818 30.5528 31.3556 30.8206 30.8895 31.2867C30.4234 31.7528 30.1556 32.379 30.1449 33.0276C30.1343 33.6761 30.3817 34.2939 30.8328 34.7449L41.0374 44.9496L30.4927 55.4944C30.0266 55.9605 29.7587 56.5867 29.7481 57.2352C29.7375 57.8838 29.9849 58.5015 30.436 58.9526C30.887 59.4037 31.5048 59.6511 32.1533 59.6405C32.8019 59.6298 33.4281 59.362 33.8942 58.8959L44.439 48.3511L54.6436 58.5557C55.0947 59.0068 55.7125 59.2543 56.361 59.2436C57.0095 59.233 57.6357 58.9652 58.1019 58.4991C58.568 58.0329 58.8358 57.4067 58.8464 56.7582C58.8571 56.1096 58.6096 55.4919 58.1585 55.0408Z"
            fill="#FFEFE1" />
    </svg>
</template>