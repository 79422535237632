<template>
    <svg class="main-logo" width="80" height="55" viewBox="0 0 80 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M43.0915 36.6336C45.1538 36.6807 47.1359 37.4432 48.6979 38.7906C50.2598 40.1381 51.3048 41.9866 51.6539 44.0198V31.0028C51.6539 29.2458 50.9559 27.5608 49.7135 26.3185C48.4712 25.0761 46.7863 24.3783 45.0294 24.3783H26.6133V36.6997L43.0915 36.6336Z"
            fill="#F26658" />
        <path
            d="M45.0148 24.3282H26.582V36.6166H42.9612C45.2687 36.621 47.4801 37.5406 49.1102 39.1738C50.7402 40.807 51.6558 43.0203 51.6558 45.3278V30.9692C51.658 30.0965 51.4874 29.2319 51.1544 28.4252C50.8215 27.6185 50.3325 26.8857 49.7154 26.2686C49.0983 25.6514 48.3655 25.1623 47.5588 24.8294C46.7521 24.4964 45.8875 24.326 45.0148 24.3282Z"
            fill="#F26658" />
        <path
            d="M62.6989 0.0830268H27.3408C26.4447 0.0772013 25.5492 0.121563 24.6581 0.215645C21.9102 0.480295 19.2184 1.16153 16.6755 2.23605C13.4134 3.60388 10.4532 5.60162 7.96435 8.11513C5.45781 10.6097 3.46084 13.5685 2.08508 16.8263C1.66479 17.9111 1.30012 19.0166 0.992188 20.1385C1.79005 17.8736 3.27915 15.9166 5.24932 14.5437C7.21948 13.1708 9.57137 12.4515 11.9724 12.4873H67.2698C68.2433 11.5 68.9155 10.2562 69.2081 8.90093C69.5008 7.54567 69.4017 6.13502 68.9223 4.83404C68.4429 3.53306 67.6033 2.39548 66.5012 1.55416C65.3991 0.71284 64.0803 0.202535 62.6989 0.0830268Z"
            fill="#F26658" />
        <path
            d="M51.6544 44.02C51.3231 41.9937 50.2943 40.1467 48.7461 38.7981C47.198 37.4495 45.2275 36.6841 43.1749 36.6338H39.2502V42.6123H27.4253C23.4444 42.6123 19.6263 41.0319 16.8098 38.2185C13.9933 35.4051 12.4086 31.5888 12.4043 27.6078C12.4086 23.6269 13.9933 19.8106 16.8098 16.9972C19.6263 14.1838 23.4444 12.6036 27.4253 12.6036H12.1892C5.36598 12.6036 0 17.4229 0 26.6971V27.5913C0 34.8605 2.88774 41.832 8.02786 46.9721C13.168 52.1123 20.1395 55 27.4087 55H41.9992C44.5599 55 47.0155 53.9828 48.8262 52.1722C50.6369 50.3615 51.6544 47.9056 51.6544 45.3449C51.7015 44.9046 51.7015 44.4603 51.6544 44.02Z"
            fill="#F26658" />
        <path
            d="M72.9533 24.3787L68.6477 28.4693C68.3187 28.8088 68.0399 29.1937 67.8196 29.6121L67.7202 29.8274C67.6494 29.9665 67.5883 30.1102 67.5378 30.2578V30.407C67.3209 31.1238 67.2747 31.8814 67.4028 32.6193C67.5308 33.3571 67.8296 34.0548 68.2753 34.6566C68.721 35.2585 69.3013 35.7475 69.9698 36.0851C70.6383 36.4227 71.3764 36.5994 72.1253 36.6008H79.7767V24.3622L72.9533 24.3787Z"
            fill="#F26658" />
        <path style="mix-blend-mode:multiply" opacity="0.72"
            d="M72.9533 24.3787L68.6477 28.4693C68.3187 28.8088 68.0399 29.1937 67.8196 29.6121L67.7202 29.8274C67.6494 29.9665 67.5883 30.1102 67.5378 30.2578V30.407C67.3209 31.1238 67.2747 31.8814 67.4028 32.6193C67.5308 33.3571 67.8296 34.0548 68.2753 34.6566C68.721 35.2585 69.3013 35.7475 69.9698 36.0851C70.6383 36.4227 71.3764 36.5994 72.1253 36.6008H79.7767V24.3622L72.9533 24.3787Z"
            fill="url(#paint0_linear_784_585)" />
        <path
            d="M67.6706 29.827C67.6706 29.976 67.538 30.1083 67.4883 30.2574C67.5388 30.1098 67.5998 29.9661 67.6706 29.827Z"
            fill="#F26658" />
        <path
            d="M79.7407 11.9738C79.6526 10.1161 79.1547 8.30058 78.2831 6.65758C77.9904 6.08332 77.6586 5.52996 77.2897 5.00147C76.487 3.93375 75.52 3.00035 74.4246 2.23589C73.9004 1.86037 73.3466 1.5281 72.7685 1.24227C71.0602 0.414813 69.1848 -0.0100932 67.2867 0.000182011H62.6992C64.0873 0.114447 65.4137 0.623182 66.5221 1.46646C67.6305 2.30975 68.4749 3.45243 68.9553 4.75968C69.4358 6.06694 69.5321 7.4842 69.2335 8.84457C68.935 10.2049 68.2538 11.4517 67.2701 12.4376C67.2701 12.4376 58.8241 19.7907 55.3794 22.7717C54.1182 23.8339 53.2233 25.2662 52.822 26.8655C52.4207 28.4648 52.5331 30.1498 53.1434 31.6816C53.2262 31.8803 53.3089 32.0625 53.4083 32.2612C54.3671 34.0878 55.9919 35.4753 57.946 36.1365C58.7582 36.4037 59.6083 36.5379 60.4633 36.5339H72.0561C71.3072 36.5324 70.5691 36.356 69.9007 36.0184C69.2322 35.6808 68.6519 35.1915 68.2061 34.5897C67.7604 33.9879 67.4616 33.2902 67.3336 32.5523C67.2056 31.8145 67.2517 31.0569 67.4686 30.3401V30.1911C67.4686 30.042 67.5847 29.9095 67.651 29.7605L67.7505 29.5452C67.9758 29.1258 68.2603 28.7408 68.5951 28.4023L72.8842 24.3118L75.2195 22.0927C76.6615 20.755 77.8407 19.16 78.6971 17.3894C79.4378 15.6826 79.7943 13.8337 79.7407 11.9738Z"
            fill="#F26658" />
        <path style="mix-blend-mode:multiply" opacity="0.72"
            d="M62.782 0.0832672H39.4141V12.5207H67.3529C68.3366 11.5348 69.0177 10.2882 69.3163 8.92786C69.6148 7.56749 69.5181 6.15003 69.0377 4.84277C68.5573 3.53551 67.7133 2.39283 66.6049 1.54955C65.4964 0.706267 64.17 0.197532 62.782 0.0832672Z"
            fill="url(#paint1_linear_784_585)" />
        <path style="mix-blend-mode:multiply" opacity="0.72"
            d="M12.6862 30.5556C12.4992 29.6009 12.4046 28.6303 12.4044 27.6574C12.4088 23.6765 13.9934 19.8601 16.8099 17.0467C19.6264 14.2333 23.4445 12.6529 27.4255 12.6529H12.1893C5.36614 12.6529 0.000160342 17.4722 0.000160342 26.7465V27.6408C-0.00339144 28.6369 0.0520704 29.6324 0.165933 30.6219L12.6862 30.5556Z"
            fill="url(#paint2_linear_784_585)" />
        <path style="mix-blend-mode:multiply" opacity="0.72"
            d="M51.6582 44.0034C51.3203 41.9771 50.2876 40.1316 48.7374 38.7838C47.1871 37.436 45.2157 36.67 43.1621 36.6172H39.2373V42.5957H28.0586V54.9171H42.0029C44.5636 54.9171 47.0193 53.9 48.8299 52.0893C50.6406 50.2786 51.6582 47.8228 51.6582 45.2621C51.6913 44.8432 51.6913 44.4223 51.6582 44.0034Z"
            fill="url(#paint3_linear_784_585)" />
        <defs>
            <linearGradient id="paint0_linear_784_585" x1="55.4979" y1="30.407" x2="80.8201" y2="30.5229"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.48" stop-color="#A23F53" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_784_585" x1="86.2157" y1="6.29369" x2="50.2285" y2="6.29369"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.48" stop-color="#A23F53" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_784_585" x1="8.04889" y1="5.33286" x2="15.2034" y2="25.4879"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.48" stop-color="#A23F53" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint3_linear_784_585" x1="62.3567" y1="31.2183" x2="33.8717" y2="50.5452"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.48" stop-color="#A23F53" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>