import { ref } from "vue";
import type { Ref } from "vue";
import { useRoute } from "vue-router";
import includes from "lodash-es/includes";
import get from "lodash-es/get";
import { LocalizedMenuItem } from "@/store/ts/interfaces/globalState.interface";
import { useGlobalStore } from "@/store/pinia/global";



export const baseConfig = (store: ReturnType<typeof useGlobalStore>) => {
    const pathEndKeywords: Ref<string[]> = ref(["singleview"]);

    const route = useRoute();

    const getMenuFromPath = (routeParams: { [key: string]: string | number; }) => {
        const pathNoLan = getPath();
        let selectedMenu = {} as LocalizedMenuItem;
        if (!store.indx) return;
        for (let i in store.indx.menus) {
            if (
                pathNoLan === "/" &&
                Number(get(store.indx.menus[i], "set_home")) === 1
            ) {
                selectedMenu = store.indx.menus[i];
                break;
            } else if (pathNoLan === store.indx.menus[i].regular_path) {
                selectedMenu = store.indx.menus[i];
                break;
            }
        }
        selectedMenu.routeParams = routeParams ? routeParams : {};
        return selectedMenu;
    };

    const splicePathArray = (start: number, arr: string[]): string[] => {
        return arr.splice(start, arr.length - start);
    }

    const getPath = (): string => {

        const path: string[] = [];

        for (let i in route.params) {
            const param = route.params[i] as string;
            if (includes(pathEndKeywords.value, param)) break;
            if (!param) break;

            path.push(param);
        }
        if (path[0] === 'projects' && path.length >= 3) {
            splicePathArray(2, path)

        }

        return "/" + path.join("/");
    };

    return {
        pathEndKeywords,
        getMenuFromPath,
        getPath,
    }

}
