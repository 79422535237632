import { getCurrentInstance, ref, watch, computed, nextTick } from "vue";
import type { Ref } from "vue";
import { useRoute } from "vue-router";
import  get  from "lodash-es/get";


import { LocalizedMenuItem } from "@/store/ts/interfaces/globalState.interface";
import {
    set_current_menu_locale_paths,
    set_current_content_from_buffer,
} from "../store/pinia/global/menu_helpers";

import { baseConfig } from "./main"
import { useGlobalStore } from "@/store/pinia/global";



export const handlePageRouting = (store: ReturnType<typeof useGlobalStore>): {
    pageRoutingSelectMenuByPath: () => void;
} => {
    const { pathEndKeywords,
        getMenuFromPath,
        getPath } = baseConfig(store);

    // INIT STORE
    const route = useRoute();

    // REFS
    const selectedMenu: Ref<LocalizedMenuItem> = ref(null);
    const defaultComponent: Ref<string> = ref("Default");
    const componentToLoad: Ref<string> = ref("");
    const loadedComponent: Ref<string> = ref("");
    const fullPath: Ref<string> = ref("");
    const routeParams: Ref<{
        [key: string]: string | number;
    }> = ref({});

    const innerTemplates: Ref<{ [key: string]: string; }> = ref({
        Default: "Default"
    });


    const currentInstance = getCurrentInstance();
    const appInstance = currentInstance.appContext;


    // METHODS

    const loadNewPage = () => {
        set_current_content_from_buffer(store);
        loadedComponent.value = componentToLoad.value;
        store.selected_menu = selectedMenu.value;
    };


    const pageRoutingSelectMenuByPath = async () => {
        // if (!_.get(this.indx, "menus.0.id")) return false;
        selectedMenu.value = getMenuFromPath(getRouteParams());
        selectedMenu.value.currentLayoutTemplate = findLayoutComponent(
            selectedMenu.value
        );
        store.selected_menu = selectedMenu.value;
        if (!selectedMenu.value) return false;
        store.getCurrentContentProto(selectedMenu.value);
        set_current_menu_locale_paths(store);
        await nextTick();
        store.triggerUpdate = !store.triggerUpdate;
        setTimeout(() => {
            store.triggerUpdate = !store.triggerUpdate;
        }, 1000);
        store.disableScrollStatus = false;
    }

    const getRouteParams = () => {
        routeParams.value = {} as { [key: string]: string | number; };
        let ready = '';
        for (let i in route.params) {
            const param = route.params[i] as string;
            if (pathEndKeywords.value.indexOf(param) >= 0) {

                ready = param;
                continue;
            }

            if (ready.length && parseInt(param)) {
                routeParams.value[ready] = parseInt(param);
                ready = '';
            }
        }

        fullPath.value = getPath();
        return routeParams.value;
    }


    /// find layout template depend on selected menu
    const findLayoutComponent = (selectedMenu: LocalizedMenuItem) => {
        let template = defaultComponent.value;

        if(selectedMenu?.primary_template){
            template = selectedMenu?.primary_template
        }

        if (get(selectedMenu, "secondary_template")) {
            template = selectedMenu.secondary_template;
        }

        /// single template load patterns
        if (parseInt(get(routeParams.value, "singleview") as string)) {
            //// load single template for content purposes
            template = getSingleViewTemplate(template); //_.get( this.innerTemplates, template);

        }

        componentToLoad.value = template;
        return template;
    };


    const getSingleViewTemplate = (template: string) => {

        let singleViewTemplate = get(store.indx, [
            "contentTypes",
            get(
                store.current_content,
                "primary.data.list.0.content_type"
            ),
            "settings",
            "single_template",
        ]);

        if (!singleViewTemplate && get(innerTemplates.value, template))
            singleViewTemplate = get(innerTemplates.value, template);

        if (appInstance.components[singleViewTemplate])
            template = singleViewTemplate;

        return template;
    };

    // COMPUTED
    const currentContentStatus = computed(() => store.current_content_request_status)

    // WATCHERS

    watch(currentContentStatus, (ns) => {
        if (ns === "done") {
            loadNewPage();
        }
    }, {
        immediate: true,
    });

    return {

        pageRoutingSelectMenuByPath,
    }

}















